.Cart {
    padding-bottom: 50px;

    h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        padding: 40px 15px 30px 15px;
        text-transform: uppercase;
        border-bottom: 2px solid var(--borderGray);
    }

    .Cart__elements {
        display: flex;
        flex-direction: column;
        width: 100%;

        .Cart__elements-element {
            width: 100%;
            border-bottom: 2px solid var(--borderGray);
            display: flex;
            justify-content: space-between;
            padding: 50px 0;

            .Cart__elements-element-leftSide {
                h3 {
                    font-weight: 800;
                    font-size: 30px;
                    color: var(--black);
                    margin-bottom: 20px;
                }





                .allAttributes {
                    display: flex;
                    flex-direction: column;
                    margin: 25px 0;
                    width: 45%;

                    p {
                        font-style: normal;
                        font-weight: 800;
                        font-size: 18px;
                        margin-top: 12px;
                    }

                    .AttributesOfElement {
                        display: flex;
                        margin: 25px 0 !important;

                        .ProductDetails__details {
                            border: 2px solid var(--black);
                            width: 120px;
                            height: 40px;
                            cursor: pointer;
                            margin-left: 15px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }

                        :first-child {
                            margin: 0 !important;
                        }
                    }
                }





            }

            .Cart__elements-element-rightSide {
                display: flex;
                justify-content: flex-end;

                .Cart__elements-icons {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    justify-content: space-between;
                    align-items: center;
                    margin-right: 20px;

                    span {
                        font-size: 30px;
                        width: 40px;
                        height: 40px;
                        border: 2px solid var(--black);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                    }

                    p {
                        font-size: 25px;
                    }

                }

                .Cart__elements__mainImg {
                    width: 60%;
                    display: flex;
                    justify-content: center;

                    img {
                        width: 100%;
                    }
                }
            }

        }
    }

    .Cart__elements-total {
        padding-top: 20px;
        color: var(--black);
        font-family: 'Raleway';

        p {
            margin-top: 10px;

            span {
                font-weight: bold;
            }
        }

        button {
            margin-top: 20px;
            width: 20%;
            border: none;
            outline: none;
            background-color: var(--primary__color);
            text-align: center;
            margin: auto;
            padding: 0.9rem 4.5rem;
            cursor: pointer;
            text-transform: uppercase;
            margin: 20px 0;

            a {
                color: var(--white);
            }
        }

    }
}

@media(max-width:1024px) {
    .Cart {
        .Cart__elements-total {
            button {
                padding: 0.9rem 0rem;
            }
        }
    }
}
@media(max-width:770px) {
    .Cart {
        .Cart__elements-total {
            button {
                padding: 0.9rem 0rem;
            }
        }
    }
}

@media(max-width:570px) {

    .Cart {
        padding-bottom: 0px !important;

        h1 {
            font-size: 22px;
            padding: 20px 15px 30px 15px;

        }

        .Cart__elements {


            .Cart__elements-element {

                padding: 20px 0;

                .Cart__elements-element-leftSide {
                    width: 50%;

                    h3 {
                        font-size: 16px;
                        margin-bottom: 10px;
                    }


                    .allAttributes {
                        margin: 5px 0;
                        width: 100%;

                        p {
                            font-size: 16px;
                            margin-top: 8px;
                        }

                        .AttributesOfElement {
                            display: flex;
                            margin: 15px 0 !important;

                            .ProductDetails__details {
                                border: 1px solid var(--black);
                                width: 140px;
                                height: 30px;
                                cursor: pointer;
                                margin-left: 5px;
                            }
                        }
                    }
                }

                .Cart__elements-element-rightSide {

                    width: 40%;

                    .Cart__elements-icons {
                        margin-right: 10px;

                        span {
                            font-size: 18px;
                            width: 20px;
                            height: 20px;
                            border: 1px solid var(--black);

                        }

                        p {
                            font-size: 18px;
                        }

                    }

                    .Cart__elements__mainImg {
                        width: 100%;

                        img {
                            width: 100%;
                        }
                    }
                }

            }
        }

        .Cart__elements-total {
            padding-top: 10px;


            p {
                margin-top: 8px;

            }

            button {
                width: 100%;
                border: none;
                outline: none;
            }

        }
    }

}