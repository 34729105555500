.Navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    padding: 1rem 0;

    .categories {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 20%;

        li {

            font-weight: 600;
            font-size: 16px;
            text-transform: uppercase;
            cursor: pointer;
            transition: .2s;

            a {
                color: var(--black);
            }

        }


    }

    .logo {
        width: 41px;
        height: 41px;
        justify-content: center;
        display: flex;


        img {
            width: 100%;
        }

    }

    .navbar__options {
        width: 380px;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .nameOption {
            cursor: default;
        }

        li {
            font-weight: 500;
            font-size: 18px;
            cursor: pointer;
            color: var(--black);
            position: relative;
            transition: .5s;

            .prices {
                overflow: auto;
            }

            ul {
                position: absolute;
                background-color: var(--white);
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                width: 130px;
                z-index: 10;
                text-align: center;
                margin: 2rem 2rem;
                right: -90px;
                display: none;
                transition: .5s;

                li {
                    padding: 20px 0;

                    &:hover {
                        background-color: var(--gray);
                    }
                }
            }

            img {
                width: 130%;
                position: relative;
            }

            .ordersNumber {
                color: var(--white);
                background-color: var(--black);
                border-radius: 50%;
                width: 20px;
                height: 20px;
                padding: 10px;
                margin: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                position: absolute;
                top: 0;
                left: 20px;
                font-size: 13px;
            }

            ul {
                position: absolute;
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                width: 330px;
                max-height: 600px;
                z-index: 10;
                text-align: center;
                margin: 2rem 2rem;
                right: -90px;
                display: none;
                overflow-y: scroll;
                padding: 0rem 15px;

                .Cart__elements {
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    .bag {
                        span {
                            font-weight: bold;
                            font-style: normal;
                            font-weight: 700;
                            font-size: 18px;
                        }

                        text-align: left;
                        font-size: 16px;
                        padding: 20px 0 0 0px;
                    }

                    .Cart__elements-element {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        padding: 20px 0px;


                        .Cart__elements-element-leftSide {
                            text-align: left;
                            width: 55%;

                            h3,
                            h2 {
                                font-weight: 300;
                                font-size: 16px;
                                color: var(--black);

                            }

                            p {
                                margin-top: 5px;
                            }

                            .AttributesOfElement {
                                display: flex;
                                margin: 15px 0 !important;

                                span {
                                    font-size: 14px;
                                }

                                .ProductDetails__details {
                                    border: 1px solid var(--black);
                                    width: 80px;
                                    height: 35px;
                                    cursor: pointer;
                                    margin-left: 5px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    padding: 0 !important;
                                }

                                :first-child {
                                    margin: 0 !important;
                                }
                            }
                        }

                        .Cart__elements-element-rightSide {
                            display: flex;
                            justify-content: flex-end;
                            width: 40%;

                            .Cart__elements-icons {
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                align-items: center;
                                margin-right: 10px;

                                span {
                                    font-size: 20px;
                                    width: 25px;
                                    height: 25px;
                                    border: 1px solid var(--black);
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    cursor: pointer;
                                }

                                p {
                                    font-size: 20px;
                                }

                            }

                            .Cart__elements__mainImg {
                                width: 100%;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                img {
                                    width: 100%;

                                }
                            }
                        }

                    }
                }

                .totalCartNav {
                    display: flex;
                    justify-content: space-between;
                    font-weight: bold;
                    margin-top: 40px;
                }

                .buttonsCartNav {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 20px;
                    padding-bottom: 25px;

                    :first-child {
                        background-color: transparent;
                        color: var(--black);
                        border: 1px solid var(--black);
                        width: 47%;
                        padding: .8rem 0;
                        text-transform: uppercase;
                    }

                    :nth-child(2) {
                        a {
                            width: 47%;
                            padding: 1rem .8rem;
                            color: var(--white);
                            background-color: var(--primary__color);
                            text-transform: uppercase;
                            border: none;
                        }

                    }
                }
            }
        }
    }
}

.active__navbar {

    border-bottom: 3px solid var(--primary__color);
    color: var(--primary__color) !important;
    padding: 1rem 0;
}

.show_dollar {
    display: block !important;
    box-shadow: 20px 20px 2px 10000px #39374871 !important;
    
    transition: .5s;

}

.show_cart {
    display: block !important
}

.focus_currunt {
    background-color: var(--gray);
    border: 3px solid var(--primary__color);
}



.allAttributes {
    display: flex;
    flex-direction: column;
    margin: 15px 0;
    width: 100%;

    p {
        font-style: normal;
        font-weight: 800;
        font-size: 16px;
    }


}




@media(max-width:770px) {
    .Navbar {
        flex-wrap: wrap;
        padding: 1.5rem 0;

        .categories {
            width: 40%;

        }

        .logo {
            width: 50%;
            margin: 2rem 0;

            img {
                width: 41px;
            }

        }


        .navbar__options {
            width: 80%;
            justify-content: space-between;
            li {
                font-size: 14px;

                ul {
                    width: 240px !important;
                    right: -110px !important;
                }

            }
        }
    }
}


@media(max-width:540px) {
    .Navbar {
        flex-wrap: wrap;
        padding: 1.5rem 0;

        .categories {
            width: 100%;

        }

        .logo {
            width: 100%;
            margin: 2rem 0;

            img {
                width: 41px;
            }

        }


        .navbar__options {
            width: 100%;

            li {
                font-size: 14px;

                ul {
                    width: 240px !important;
                    right: -110px !important;
                }

            }
        }
    }
}