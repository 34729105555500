@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;600;700;800&display=swap');

:root {
  --white: #fff;
  --black: #1D1F22;
  --primary__color: #5ECE7B;
  --gray: #EEEEEE;
  --heavy__gray: #8D8F9A;
  --borderGray:#E5E5E5;
  --light__green:#22382873;
  --pink:#AF40FF

  
}

body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  /* overflow: hidden; */

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;

}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  outline: 0;
  border: 0;
  transition: 0.4s;
}

html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}


img {
  display: block;
  width: 100%;
  object-fit: cover;
}

ul {
  list-style: none;
}

.container {
  width: 90%;
  margin: auto;
}
.inputError {
  text-align: left;
  width: 100%;
  color: red;
  font-size: .8rem;
  margin-top: 3px;
}