.ProductDetails {
    display: flex;
    width: 90%;
    justify-content: space-between;
    margin: 4rem auto;

    .ProductDetails__models {
        width: 15%;
        display: flex;
        flex-direction: column;
        row-gap: 2rem;

        div {
            width: 80%;

            img {
                width: 100%;

            }
        }
    }

    .ProductDetails__mainImg {
        width: 45%;

        img {
            width: 70%;
        }
    }

    .ProductDetails__details {
        width: 35%;
        display: flex;
        flex-direction: column;

        h3 {
            font-weight: 800;
            font-size: 30px;
            color: var(--black);
        }

        h2 {
            font-weight: normal;
            margin-top: 10px;
            font-size: 30px;
        }

        .ProductDetails__details-divDetails {
            margin-top: 20px;

            p {
                font-style: normal;
                font-weight: 800;
                font-size: 18px;
            }

            ul {
                display: flex;
                width: 50%;
                justify-content: space-between;
                margin: 15px 0;

                :first-child {
                    margin-left: 0;
                }

                li {
                    width: 25px;
                    height: 25px;
                    border: 1px solid var(--black);
                    padding: 1.2rem 1.4rem;
                    cursor: pointer;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    margin-left: 10px;
                    font-size: .7rem;
                }
            }
        }


        .ProductDetails__details-divPrice {
            p {
                font-style: normal;
                font-weight: 800;
                font-size: 18px;
                margin-top: 10px;
            }

            ul {
                display: flex;
                width: 50%;
                justify-content: space-between;
                margin: 15px 0;

                li {
                    border: 1px solid var(--black);
                    padding: .7rem 1rem;
                    cursor: pointer;
                }
            }
        }

        .ProductDetails__details-divButton {
            margin: 25px 0;

            button {
                width: 65%;
                border: none;
                outline: none;
                background-color: var(--primary__color);
                color: var(--white);
                text-align: center;
                padding: 0.9rem 4.5rem;
                cursor: pointer;
            }
        }

        .ProductDetails__details-divDiscription {
            width: 65%;

            p {
                font-weight: 400;
                font-size: 16px;
                line-height: 159.96%;
            }
        }
    }

}

.borderActive {
    // border: 2px solid var(--primary__color) !important;
    transition: .2ms;
    background-color: var(--black);
    color: var(--white);
}

.shadowActive {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 10px 25px;
    padding: 5px;
    border: 3px solid var(--primary__color) !important;
    outline: none;
    transition: .4s;
}

.disabledButton {
    background-color: gray !important;
    cursor: context-menu !important;
}


@media(max-width:770px) {
    .ProductDetails {

        margin: 2rem auto;
        flex-wrap: wrap;

        .ProductDetails__models {
            width: 100%;
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;
            row-gap: 1rem;
            order: 3;

            div {
                width: 30%;

                img {
                    width: 100%;

                }
            }
        }

        .ProductDetails__mainImg {
            width: 100%;

            img {
                width: 100%;
            }
        }

        .ProductDetails__details {
            width: 100%;


            h3 {
                margin-top: 10px;
                font-size: 22px;
            }

            h2 {
                margin-top: 10px;
                font-size: 22px;
            }

            .ProductDetails__details-divDetails {
                margin-top: 20px;

                p {
                    font-size: 16px;
                }

                ul {
                    display: flex;
                    width: 50%;
                    justify-content: space-between;
                    margin: 15px 0;

                    :first-child {
                        margin-left: 0;
                    }

                    li {
                        width: 20px;
                        height: 20px;
                        padding: 1rem 1rem;

                    }
                }
            }


            .ProductDetails__details-divPrice {
                p {
                    font-size: 16px;
                    margin-top: 5px;
                }


            }

            .ProductDetails__details-divButton {
                margin: 25px 0;

                button {
                    width: 100%;

                }
            }

            .ProductDetails__details-divDiscription {
                width: 100%;

                p {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 159.96%;
                }
            }
        }

    }

}


