.Register {
    width: 100%;
    height: 100vh;
    background: var(--light__green);
    background-image: url("../../../assets/bgIMG.png");
    background-position: center;
    background-size: cover;
    position: relative;

    .Register__card {
        display: flex;
        justify-content: space-between;
        width: 70%;
        margin: auto;
        transform: translateY(50px);
        border-radius: 10px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

        .Register__card--logoSide {
            width: 40%;
            height: 595px;
            background-color: var(--primary__color);
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .Register__card--logoSide-img {
                width: 60%;

                img {
                    width: 80%;
                }
            }


        }

        .Register__card--formSide {
            width: 60%;
            height: 100%;
            background-color: var(--white);
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;

            h3 {
                padding: 2rem 0 0 2rem;
                font-size: 2rem;
            }

            .loginForm {
                padding: 8rem 3rem !important;
            }

            .Register__card--formSide-inputs {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 90%;
                padding: 4rem 3rem;
                border: 2px solid var(--primaryColor);
                box-shadow: 2px 2px 25px 1px var(--primaryColor);


                input {
                    border-bottom: 2px solid var(--primary__color);
                    outline: none;
                    margin: 20px 0;
                }



                .FormBtn {
                    padding: 0.6rem 2rem;
                    font-size: 1.2rem;
                    background-color: transparent;
                    color: var(--black);
                    border: solid 2px var(--primary__color);
                    margin-bottom: 0px;
                    border-radius: .4rem;
                    font-weight: bold;
                    cursor: pointer;
                    transition: .3s;
                    margin-bottom: 20px;
                    &:hover {
                        color: var(--white);
                        background-color: var(--primary__color);
                    }
                }

                .registeredError {
                    margin-bottom: 0;
                    width: 100%;
                    text-align: center;
                    color: red;
                    font-size: 1rem;
                    padding: 0;
                }

                .have {
                    color: var(--primaryColor);
                    font-weight: bold;
                    font-size: 1.2rem;

                    a {
                        color: var(--primary__color);
                        text-decoration: underline;
                    }
                }

            }
        }
    }

}

@media(max-width:770px) {
    .Register {


        .Register__card {
            width: 90%;

            margin: auto;
            transform: translateY(0px);
            flex-wrap: wrap;

            .Register__card--logoSide {
                width: 100%;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                border-bottom-left-radius: 0px;


                .Register__card--logoSide-img {
                    width: 100%;
                    
                    img {
                        width: 100%;
                    }
                }


            }

            .Register__card--formSide {
                width: 100%;
                height: 100%;
                border-top-right-radius: 0px;
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;

                h3 {
                    padding: 2rem 0 0 2rem;
                    font-size: 1.5rem;
                }

                .loginForm {
                    padding: 4rem 1rem !important;
                    width: 100%;
                }

                .Register__card--formSide-inputs {
                    height: 90%;
                    padding: 1rem .5rem;
                    input{
                        margin: 10px 0;
                    }
                    .FormBtn {
                        padding: 0.4rem 1.5rem;
                        font-size: 1rem;
                        margin-bottom: 10px;
                    }


                }
            }
        }

    }
}

